import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from "./routes/routes";

function App() {
  return( 
  <Router>
    <AppRouter />
  </Router>
);
}

export default App;
