const PrivacyPolicyPage = () => {
  return (
    <div className="bg-gray-100 py-12">
      <div className="container mx-auto px-6 lg:px-12">
        <div className="bg-white shadow-lg rounded-lg p-8 lg:p-16">
          <h1 className="md:text-5xl text-3xl font-bold text-center mb-10 text-[#1FCC79]">FaNect: Privacy Policy</h1>

          <section className="mb-8">
            <h2 className="md:text-3xl text-2xl font-semibold mb-4">Introduction</h2>
            <p className="text-lg leading-relaxed text-gray-800">
              <span className="font-bold text-xl">Superfan Limited</span>, the operator and owner of the FaNect application and service (<span className="font-bold">“FaNect”</span>) acknowledges that, in providing FaNect to you, it may collect, receive, or process personal data of individuals.
            </p>
            <p className="text-lg leading-relaxed text-gray-800 mt-4">
              <span className="font-bold">FaNect</span> is a social media application and service that provides registered users (<span className="font-bold">“Users”</span>) with direct media content including videos, reels, pictures, sound, live streams, and other exclusives from celebrities under a paid subscription. FaNect also allows Users to interact and engage with celebrities with curated content (<span className="font-bold">“Celebrities”</span>) through the channels on the application.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="md:text-3xl text-2xl font-semibold mb-4">Data Collection and Use</h2>
            <p className="text-lg leading-relaxed text-gray-800">
              Any personal data we collect or process shall be used, processed, reserved, or stored solely to further the services, content being uploaded, viewed, or delivered through FaNect or advertising-related activities (the <span className="font-bold">“Services”</span>) in accordance with Terms of Use and in compliance with applicable laws. 
            </p>
            <p className="text-lg leading-relaxed text-gray-800 mt-4">
              When you use these Services, you’ll share some information with us. This Privacy Policy provides details about the information we collect, how we use it, whom we share it with, and the controls we give you to access, update, and delete your information.
            </p>
            <p className="text-lg leading-relaxed text-gray-800 mt-4">
              We shall ensure full compliance with the Nigeria Data Protection Regulation, 2019 (NDPR) and other Data Protection Laws in processing any personal data disclosed by all Users of FaNect including Celebrities and Fans.
            </p>
            <p className="text-lg leading-relaxed text-gray-800 mt-4">
            In relation to any personal data received or processed in connection with the provision of the Services, we will take appropriate steps to ensure that all our employees and officers with access to such personal data (on a need-to-know basis) comply with the NDPR and we will also put appropriate security measures in place to protect the personal data against un-authorized or unlawful access, loss, manipulation or damage.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="md:text-3xl text-2xl font-semibold mb-4">Information You Provide</h2>
            <p className="text-lg leading-relaxed text-gray-800">
            When you interact with our Services, we collect information that you provide to us. For example, most of our services require you to set up a FaNect account, so we need to collect a few important details about you, such as your name, username, password, email address, phone number, and date of birth. We may also ask you to provide us with some additional information that will be publicly visible on our services, such as a profile picture and in order to make payment for the Services. We may require you to provide us with a debit and credit card number and its associated account information.
            </p>
            <p className="text-lg leading-relaxed text-gray-800 mt-4">
            Please note that content shared on FaNect may be copied by other Users or may become available on other digital channels, thus, ensure that you do not send or share messages or share content that you wouldn’t want someone to save or share.
            </p>
            <p className="text-lg leading-relaxed text-gray-800 mt-4">
            When you contact customer support or communicate with us in any other way, we’ll collect whatever information you volunteer or that we need to resolve your query or respond to your request. 
            </p>
          </section>

          <section className="mb-8">
            <h2 className="md:text-3xl text-2xl font-semibold mb-4">Information We Get When You Use Our Services</h2>
            <p className="text-lg leading-relaxed text-gray-800">In accessing the Services, we collect the following information from you –</p>
            <ol className="list-decimal space-y-4 list-inside leading-relaxed text-gray-800 mt-4 text-lg leading-relaxed">
        <li>
            When you use our Services, we collect information about your interactions within FaNect and also with other Users or Celebrities (<span className="font-bold">“Usage Information”</span>). This Usage Information, including the time and date of your communications, the number of messages you exchange with other Users and your interactions with messages (such as when you open a message or capture a screenshot);
        </li>
        <li>
            Content Information. We collect content you create on our Services, and information about the content you create or provide, such as if the recipient has viewed the content and the metadata that is provided with the content.
        </li>
        <li>
            Camera and Photos. Some features on FaNect may require us to collect images and other information from your device’s camera and photos where you have granted permission.
        </li>
        <li>
            Location Information. When you use our services we may collect information about your location. With your permission, we may also collect information about your precise location using methods that include GPS, wireless networks, cell towers, Wi-Fi access points, and other sensors, such as gyroscopes, accelerometers, and compasses.
        </li>
        <li>
            Device Information. We collect information from and about the devices you use, including information about your hardware and software, such as the hardware model, operating system version, device memory, advertising identifiers, unique application identifiers, apps installed, unique device identifiers, browser type, keyboards installed, language, battery level, and time zone;
        </li>
        <li>
            Device Phonebook. We may with your permission collect information from your device’s phonebook.
        </li>
        <li>
            Information Collected by Cookies and Other Technologies. Like most online services and mobile applications, we may use cookies and other technologies, such as web beacons, web storage, and unique advertising identifiers, to collect information about your activity, browser, and device. We may also use these technologies to collect information when you interact with Services we offer through one of our partners, such as advertising and commerce features. Please note that removing or rejecting cookies could affect the availability and functionality of FaNect.
        </li>
        <li>
            Log Information. We may also collect log information when you use our application, such as: details about how you’ve used our Services; device information, such as your web browser type and language; access times; pages viewed; IP address; identifiers associated with cookies or other technologies that may uniquely identify your device or browser.
        </li>
    </ol>
          </section>

          <section className="mb-8">
            <h2 className="md:text-3xl text-2xl font-semibold mb-4">How We Use Information</h2>
            <p className="text-lg leading-relaxed text-gray-800">
            Information we collect, is used to provide, develop, operate, improve, deliver the Services, as well as to 
            provide and improve our advertising services, advertisement targeting and measurement, including through the use of your precise location information (again, if you’ve given us permission to collect that information), both on and off our services. We may share information provided to us with third parties that provide services to us or perform business purposes for us aggregated, non-personally identifiable, or de-identified information.
            </p>
            <p className="text-lg leading-relaxed text-gray-800 mt-4">
            We also use the information provided to us verify your identity and prevent fraud or other unauthorized or illegal activity, and to enforce, investigate, and report conduct violating our Terms of Service and other usage policies, respond to requests from law enforcement, and comply with legal requirements.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="md:text-3xl text-2xl font-semibold mb-4">Third-Party Integrations, Features and Connections</h2>
            <p className="text-lg leading-relaxed text-gray-800">
            Our Services may contain third-party content and integrations, and we are not responsible for how those third parties collect or use your information. As always, we encourage you to review the privacy policies of every third-party service that you visit or use, including those third parties you interact with through FaNect. 
            </p>
            <p className="text-lg leading-relaxed text-gray-800 mt-4">
            Keep in mind that, while our systems are designed to carry out our deletion practices automatically, we cannot promise that deletion will occur within a specific timeframe. There may be legal requirements to store your data and we may need to suspend those deletion practices if we receive valid legal process asking us to preserve content, if we receive reports of abuse or other Terms of Service violations, or if your account or content created by you is flagged by others or our systems for abuse or other Terms of Service violations. Finally, we may also retain certain information in backup for a limited period of time or as required by law.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="md:text-3xl text-2xl font-semibold mb-4">Control Over Your Information</h2>
            <p className="text-lg leading-relaxed text-gray-800">We want you to be in control of your information, so we provide you with the following tools:</p>
            <ul className="list-decimal space-y-4 text-lg list-inside leading-relaxed text-gray-800 mt-4">
              <li><strong>Access and Correction:</strong> You can access and edit most of your basic account information right on the apps, subject to verifying your identity or provide additional information before we let you access or update your personal information. We may also reject your request to access or update your personal information for a number of reasons, including, for example, if the request risks the privacy of other users or is unlawful.</li>
              <li><strong>Revoking Permissions:</strong> In most cases, if you let us use your information, you can simply revoke your permission by changing the settings on FaNect or on your device if your device offers those options. </li>
              <li><strong>Deletion:</strong> You may  delete some information or content on FaNect. You may also delete FaNect. However, we may be required to retain personal information and data in compliance with applicable law or for any other lawful purpose. </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="md:text-3xl text-2xl font-semibold mb-4">Data Transfers</h2>
            <p className="text-lg leading-relaxed text-gray-800">
            We may collect your personal information from, transfer it to, and store and process it in accordance with the NDPR. Whenever we share information outside of where you live, when we are legally required to do so, we make sure an adequate transfer mechanism is in place. We also make sure any third parties we share information with have an adequate transfer mechanism in place, as well. 
            </p>
          </section>

          <section className="mb-8">
            <h2 className="md:text-3xl text-2xl font-semibold mb-4">Children</h2>
            <p className="text-lg leading-relaxed text-gray-800">
            Our Services are not intended for and we don’t direct them to anyone under 16. And that’s why we do not knowingly collect personal information from anyone under 16. 
            </p>
          </section>

          <section className="mb-8">
            <h2 className="md:text-3xl text-2xl font-semibold mb-4">Revisions to the Privacy Policy</h2>
            <p className="text-lg leading-relaxed text-gray-800">
                We may change this Privacy Policy from time to time. But when we do, we will provide you with a link to the updated Privacy Policy and indicate the effective date.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;

