import HomePage from '../pages/HomePage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';


const routes = [
    { path: '/', element: <HomePage /> },
    { path: '/privacy-policy/95732', element: <PrivacyPolicyPage /> },
];

export default routes;
